<template>
    <div class="lvya-certificate">
        <img src="static/lvya-old/banner7.png" alt="" style="width:100%;">
        <div class="certificate-div1">
            <p class="second_lb_title">荣誉资质</p>
            <img src="static/lvya-old/banner8.png" alt="" style="width:100%;">
            <p class="second_lb_title">证书资质与软件著作权</p>
        </div>
        <div class="certificate-div2">
            <div class="ccie_box">
                <img src="static/lvya-old/banner10.png" style="margin-right:70px;">
                <img src="static/lvya-old/banner10-1.png" style="margin-right:70px;">
                <img src="static/lvya-old/banner11.png">
            </div>
        </div>
        <div class="certificate-div3">
            <img src="static/lvya-old/banner9.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    created() {
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
.lvya-certificate{
    width: 100%;
    min-width: 1200px;
    .certificate-div1{
        width: 1000px;
        margin: 0 auto;
    }
    .second_lb_title {
        font-size: 20px;
        padding: 30px 0 10px;
    }
    .second_lb_title:before {
        content: " ";
        margin-right: 10px;
        border-left: 8px solid #05b21a;
    }
    .certificate-div2{
        width: 100%;
        background: #eee;
    }
    .ccie_box {
        width: 1000px;
        margin: 0 auto;
        background: #eee;
        text-align: center;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
    }
    .ccie_box img {
        display: inline-block;
        height: 180px;
    }
    .certificate-div3{
        width: 1000px;
        margin: 50px auto;
    }
}
</style>
